import React from 'react'
import styled from 'styled-components'

// Ui component
import Heading from '../Heading'
import Paragraph from '../Paragraph'
import Wysiwyg from '../Wysiwyg'

const TitleText = React.memo((props) => {
	const { heading, text, level, wysiwyg } = props

	return (
		<Wrapper {...props}>
			{heading && (
				<Header className="tt-header" as={Heading} level={level}>
					{heading}
				</Header>
			)}
			{wysiwyg ? <Wysiwyg>{text}</Wysiwyg> : <Paragraph>{text}</Paragraph>}
		</Wrapper>
	)
})

const Wrapper = styled.div`
	margin: 0;
	margin-bottom: 19em;

	@media (max-width: 1024px) {
		margin-bottom: 4.8rem;

		h2 {
			font-size: 2rem;
			line-height: 2.75rem;
		}
	}

	@media (max-width: 525px) {
		h2 {
			font-size: 1.875rem;
			line-height: 2.5rem;
		}
	}
`

const Header = styled.h1`
	font-size: 2.625rem;
	line-height: 3.75rem;
	letter-spacing: 0;
	margin-bottom: 2rem;
	max-width: 600px;

	@media (max-width: 1024px) {
		margin-bottom: 24px;
	}
`

export default TitleText
