import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Ui components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/ui/Container'
import TitleText from '../components/ui/TitleText'
import Heading from '../components/ui/Heading'

const PrivacyPolicy = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query PrivacyPolicy {
			timacum {
				page(id: "5f4f4df926c93053feb5aaaa") {
					id
					Title
					Fields {
						... on TIMACUM_ComponentContentText {
							id
							Text
						}
						... on TIMACUM_ComponentContentTitleText {
							id
							Text
							Title
						}
					}
				}
			}
		}
	`)

	// Data
	const { Title, Fields } = data.timacum.page

	return (
		<Layout location={props.location}>
			<SEO title={Title} />
			<Container>
				<PHeading as={Heading} level={1}>
					{Title}
				</PHeading>
				{Fields.map((field, index) => {
					return (
						<PTitleText
							key={'field' + index}
							as={TitleText}
							heading={field.Title}
							text={field.Text}
							level={2}
							wysiwyg
						/>
					)
				})}
			</Container>
		</Layout>
	)
})

const PHeading = styled.div`
	margin-bottom: 2rem;

	h1 {
		font-size: 2.625rem;
		line-height: 3.75rem;
	}

	@media (max-width: 1024px) {
		margin-bottom: 24px;

		h1 {
			font-size: 2rem;
			line-height: 2.75rem;
		}
	}

	@media (max-width: 525px) {
		h1 {
			font-size: 1.875rem;
		}
	}
`
const PTitleText = styled.div`
	div {
		max-width: 100%;
	}

	a {
		text-transform: lowercase;
		color: ${({ theme }) => theme.blendLink};
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 525px) {
		a {
			font-size: 0.875rem;
			line-height: 1.625rem;
		}
	}
`

export default PrivacyPolicy
